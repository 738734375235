'use client';

import { useEffect } from 'react';
import { Box, Button, Paper, PaperProps, Typography } from '@mui/material';
import { Trans } from '@lingui/macro';
import Image from 'next/image';
import errorIcon from '@/assets/icons/error.svg';
import { PageLayout } from '@/components/layouts/page-layout';

export type ErrorBoundaryProps = {
  error: Error;
  reset: () => void;
  PaperProps?: PaperProps;
};

export default function ErrorBoundary({
  error,
  reset,
  PaperProps
}: ErrorBoundaryProps) {
  useEffect(() => {
    console.error(error.name, error.message);
    if (window.datadogRum) {
      window.datadogRum.addError(error);
    }
  }, [error]);

  return (
    <PageLayout maxWidth="md" sx={{ minHeight: 'calc(100vh - 80px)' }}>
      <Paper sx={{ p: 5 }} {...PaperProps}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Image src={errorIcon} alt="Error" height="163" width="163" />
          <Typography fontSize="20px" fontWeight="600" lineHeight="26px">
            <Trans id="error-template.title">Error</Trans>
          </Typography>
          <Typography fontSize="14px" lineHeight="20px" sx={{ mt: 2 }}>
            <Trans id="error-template.description">
              Sorry, an unexpected error occurred. Please try again later.
            </Trans>
          </Typography>
          <Button onClick={reset} sx={{ mt: 3 }}>
            <Trans id="error-template.cta">Retry</Trans>
          </Button>
        </Box>
      </Paper>
    </PageLayout>
  );
}
